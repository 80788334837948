import React, { useContext, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WorldFilterContext } from "../contexts/WorldFilter.context";
import useToggle from "../hooks/useToggleState";
//Todays World according to zwift (0 is empty, then 1 for first day until 31)
const monthlist = [
  "zero",
  ["Richmond", "London"],
  ["Richmond", "London"],
  ["Innsbruck", "Richmond"],
  ["France", "Paris"],
  ["France", "Paris"],
  ["Makuri Islands", "New York"],
  ["Makuri Islands", "New York"],
  ["Scotland", "Makuri Islands"],
  ["Scotland", "Makuri Islands"],
  ["France", "Paris"],
  ["France", "Paris"],
  ["London", "Yorkshire"],
  ["London", "Yorkshire"],
  ["Makuri Islands", "New York"],
  ["Makuri Islands", "New York"],
  ["Scotland", "Makuri Islands"],
  ["Scotland", "Makuri Islands"],
  ["Makuri Islands", "New York"],
  ["Makuri Islands", "New York"],
  ["France", "Paris"],
  ["France", "Paris"],
  ["Innsbruck", "Richmond"],
  ["Innsbruck", "Richmond"],
  ["Makuri Islands", "New York"],
  ["Makuri Islands", "New York"],
  ["London", "Yorkshire"],
  ["London", "Yorkshire"],
  ["Richmond", "London"],
];

//current day
const currentdate = new Date();
const currentday = currentdate.toLocaleString("default", {
  timeZone: "America/New_York",
  day: "numeric",
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  worldchips: {
    padding: "0px 5px 5px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.3),
    minWidth: "120px",
  },
  containerswitch: {
    width: "280px",
    padding: "0px 0px 5px",
  },
}));

function WorldFilter() {
  const classes = useStyles();

  // Import from context
  const { toggleWorld, chosenworld, setchosenworld } =
    useContext(WorldFilterContext);

  // Definition of the toggle states for the Worlds

  const [isWatopia, toggleIsWatopia] = useToggle(chosenworld.includes("Watopia"));
  const [isLondon, toggleIsLondon] = useToggle(chosenworld.includes("London"));
  const [isNewYork, toggleIsNewYork] = useToggle(chosenworld.includes("New York"));
  const [isInnsbruck, toggleIsInnsbruck] = useToggle(chosenworld.includes("Innsbruck"));
  const [isYorkshire, toggleIsYorkshire] = useToggle(chosenworld.includes("Yorkshire"));
  const [isRichmond, toggleIsRichmond] = useToggle(chosenworld.includes("Richmond"));
  const [isFrance, toggleIsFrance] = useToggle(chosenworld.includes("France"));
  const [isParis, toggleIsParis] = useToggle(chosenworld.includes("Paris"));
  const [isMakuri, toggleIsMakuri] = useToggle(chosenworld.includes("Makuri Islands"));
  const [isScotland, toggleIsScotland] = useToggle(chosenworld.includes("Scotland"));

  // empty function for select all or clear all
  const donothing = () => {};

  // state and handler for switch
  const currentguestworld = monthlist[currentday];

  const worldswitchoff = () => {
    setchosenworld([
      "Watopia",
      "London",
      "New York",
      "Innsbruck",
      "Yorkshire",
      "Richmond",
      "France",
      "Paris",
      "Makuri Islands",
      "Scotland",
    ]);
    isWatopia ? donothing() : toggleIsWatopia();
    isLondon ? donothing() : toggleIsLondon();
    isNewYork ? donothing() : toggleIsNewYork();
    isInnsbruck ? donothing() : toggleIsInnsbruck();
    isYorkshire ? donothing() : toggleIsYorkshire();
    isRichmond ? donothing() : toggleIsRichmond();
    isFrance ? donothing() : toggleIsFrance();
    isParis ? donothing() : toggleIsParis();
    isMakuri ? donothing() : toggleIsMakuri();
    isScotland ? donothing() : toggleIsScotland();
  };
  const worldswitchon = () => {
    setchosenworld(["Watopia", currentguestworld[0], currentguestworld[1]]);
    isWatopia ? donothing() : toggleIsWatopia();
    currentguestworld[0] === "London" || currentguestworld[1] === "London"
      ? isLondon
        ? donothing()
        : toggleIsLondon()
      : isLondon
      ? toggleIsLondon()
      : donothing();
    currentguestworld[0] === "New York" || currentguestworld[1] === "New York"
      ? isNewYork
        ? donothing()
        : toggleIsNewYork()
      : isNewYork
      ? toggleIsNewYork()
      : donothing();
    currentguestworld[0] === "Innsbruck" || currentguestworld[1] === "Innsbruck"
      ? isInnsbruck
        ? donothing()
        : toggleIsInnsbruck()
      : isInnsbruck
      ? toggleIsInnsbruck()
      : donothing();
    currentguestworld[0] === "Yorkshire" || currentguestworld[1] === "Yorkshire"
      ? isYorkshire
        ? donothing()
        : toggleIsYorkshire()
      : isYorkshire
      ? toggleIsYorkshire()
      : donothing();
    currentguestworld[0] === "Richmond" || currentguestworld[1] === "Richmond"
      ? isRichmond
        ? donothing()
        : toggleIsRichmond()
      : isRichmond
      ? toggleIsRichmond()
      : donothing();
    currentguestworld[0] === "France" || currentguestworld[1] === "France"
      ? isFrance
        ? donothing()
        : toggleIsFrance()
      : isFrance
      ? toggleIsFrance()
      : donothing();
    currentguestworld[0] === "Paris" || currentguestworld[1] === "Paris"
      ? isParis
        ? donothing()
        : toggleIsParis()
      : isParis
      ? toggleIsParis()
      : donothing();
    currentguestworld[0] === "Makuri Islands" || currentguestworld[1] === "Makuri Islands"
      ? isMakuri
        ? donothing()
        : toggleIsMakuri()
      : isMakuri
      ? toggleIsMakuri()
      : donothing();
    currentguestworld[0] === "Scotland" || currentguestworld[1] === "Scotland"
      ? isScotland
        ? donothing()
        : toggleIsScotland()
      : isScotland
      ? toggleIsScotland()
      : donothing();
  };

  const [todaysWorld, setTodaysWorld] = React.useState(false);

  const handleChangeTodaysWorld = (event) => {
    setTodaysWorld(event.target.checked);
    todaysWorld ? worldswitchoff() : worldswitchon();
  };

  return (
    <div className={classes.root}>
      <div className={classes.worldchips}>
        <div className={classes.containerswitch}>
          <FormControlLabel
            control={
              <Switch
                checked={todaysWorld}
                onChange={handleChangeTodaysWorld}
                name="todaysWorld"
                inputProps={{ "aria-label": "todaysWorld" }}
                color="primary"
              />
            }
            label="Only today's worlds"
            labelPlacement="start"
          />
        </div>

        <Chip
          className={classes.chip}
          label="Watopia"
          onClick={() => {
            toggleWorld("Watopia");
            toggleIsWatopia();
            setTodaysWorld(false);
          }}
          variant={isWatopia ? "default" : "outlined"}
          color={isWatopia ? "primary" : "default"}
          style={{ color: isWatopia ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="London"
          onClick={() => {
            toggleWorld("London");
            toggleIsLondon();
            setTodaysWorld(false);
          }}
          variant={isLondon ? "default" : "outlined"}
          color={isLondon ? "primary" : "default"}
          style={{ color: isLondon ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="New York"
          onClick={() => {
            toggleWorld("New York");
            toggleIsNewYork();
            setTodaysWorld(false);
          }}
          variant={isNewYork ? "default" : "outlined"}
          color={isNewYork ? "primary" : "default"}
          style={{ color: isNewYork ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Innsbruck"
          onClick={() => {
            toggleWorld("Innsbruck");
            toggleIsInnsbruck();
            setTodaysWorld(false);
          }}
          variant={isInnsbruck ? "default" : "outlined"}
          color={isInnsbruck ? "primary" : "default"}
          style={{ color: isInnsbruck ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Yorkshire"
          onClick={() => {
            toggleWorld("Yorkshire");
            toggleIsYorkshire();
            setTodaysWorld(false);
          }}
          variant={isYorkshire ? "default" : "outlined"}
          color={isYorkshire ? "primary" : "default"}
          style={{ color: isYorkshire ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Richmond"
          onClick={() => {
            toggleWorld("Richmond");
            toggleIsRichmond();
            setTodaysWorld(false);
          }}
          variant={isRichmond ? "default" : "outlined"}
          color={isRichmond ? "primary" : "default"}
          style={{ color: isRichmond ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="France"
          onClick={() => {
            toggleWorld("France");
            toggleIsFrance();
            setTodaysWorld(false);
          }}
          variant={isFrance ? "default" : "outlined"}
          color={isFrance ? "primary" : "default"}
          style={{ color: isFrance ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Paris"
          onClick={() => {
            toggleWorld("Paris");
            toggleIsParis();
            setTodaysWorld(false);
          }}
          variant={isParis ? "default" : "outlined"}
          color={isParis ? "primary" : "default"}
          style={{ color: isParis ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Makuri Islands"
          onClick={() => {
            toggleWorld("Makuri Islands");
            toggleIsMakuri();
            setTodaysWorld(false);
          }}
          variant={isMakuri ? "default" : "outlined"}
          color={isMakuri ? "primary" : "default"}
          style={{ color: isMakuri ? "" : "#b4b4b4" }}
        />
        <Chip
          className={classes.chip}
          label="Scotland"
          onClick={() => {
            toggleWorld("Scotland");
            toggleIsScotland();
            setTodaysWorld(false);
          }}
          variant={isScotland ? "default" : "outlined"}
          color={isScotland ? "primary" : "default"}
          style={{ color: isScotland ? "" : "#b4b4b4" }}
        />
        <div>
          <Chip
            className={classes.chip}
            size="small"
            label="clear all"
            onClick={() => {
              setchosenworld([]);
              isWatopia ? toggleIsWatopia() : donothing();
              isLondon ? toggleIsLondon() : donothing();
              isNewYork ? toggleIsNewYork() : donothing();
              isInnsbruck ? toggleIsInnsbruck() : donothing();
              isYorkshire ? toggleIsYorkshire() : donothing();
              isRichmond ? toggleIsRichmond() : donothing();
              isFrance ? toggleIsFrance() : donothing();
              isParis ? toggleIsParis() : donothing();
              isMakuri ? toggleIsMakuri() : donothing();
              isScotland ? toggleIsScotland() : donothing();
              setTodaysWorld(false);
            }}
            variant="outlined"
            color="secondary"
          />
          <Chip
            className={classes.chip}
            size="small"
            label="select all"
            onClick={() => {
              setchosenworld([
                "Watopia",
                "London",
                "New York",
                "Innsbruck",
                "Yorkshire",
                "Richmond",
                "France",
                "Paris",
                "Makuri Islands",
                "Scotland",
              ]);
              isWatopia ? donothing() : toggleIsWatopia();
              isLondon ? donothing() : toggleIsLondon();
              isNewYork ? donothing() : toggleIsNewYork();
              isInnsbruck ? donothing() : toggleIsInnsbruck();
              isYorkshire ? donothing() : toggleIsYorkshire();
              isRichmond ? donothing() : toggleIsRichmond();
              isFrance ? donothing() : toggleIsFrance();
              isParis ? donothing() : toggleIsParis();
              isMakuri ? donothing() : toggleIsMakuri();
              isScotland ? donothing() : toggleIsScotland();
              setTodaysWorld(false);
            }}
            variant="outlined"
            color="secondary"
          />
        </div>
      </div>
    </div>
  );
}
export default memo(WorldFilter);
